import React, { useState } from 'react';

const faqs = [
    {
        question: "1.Why should I choose Drupal for my website?",
        answer: "Drupal is a highly flexible and secure CMS that supports scalable, enterprise-level websites. As a leading Drupal CMS development company, we help businesses build custom solutions that require unique functionality, seamless third-party integrations, and high performance."
    },
    {
        question: "2.Can you help migrate my existing website to Drupal?",
        answer: "Yes. Our Drupal migration experts ensure a seamless transition from other CMS platforms or older Drupal versions, maintaining data integrity and minimizing downtime."
    },
    {
        question: "3.How long does it take to develop a Drupal website?",
        answer: "The timeline depends on the project’s complexity, features, and customization requirements. A basic website may take a few weeks, while a complex solution could take several months. Our offshore Drupal development team ensures efficient project delivery without compromising quality."
    },
    {
        question: "4.Do you provide post-launch support?",
        answer: "Yes. Our ongoing support and maintenance services include security updates, bug fixes, performance optimization, and feature enhancements to keep your Drupal website running smoothly."
    },
    {
        question: "5.How much does it cost to develop a Drupal website?",
        answer: "The cost varies based on design complexity, custom functionalities, integrations, and project scope. Contact us for a tailored quote based on your business requirements."
    }
];

const FAQsBlock = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='faqs-block'>
            <h2 className='faqs-heading'>FAQs</h2>
            <ul className='faqs-list'>
                {faqs.map((faq, index) => (
                    <li className={`faqs-item ${openIndex === index ? 'active' : ''}`} key={index}>
                        <h3 className='faqs-questions' onClick={() => toggleFAQ(index)} style={{ cursor: 'pointer' }}>
                            {faq.question}
                        </h3>
                        {openIndex === index && <p className='faqs-answer'>{faq.answer}</p>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FAQsBlock;
import React, { Component } from "react";

export default class BannerEnquiry extends Component {
  render() {
    return (
      <section className="header-title">
        <h1>Enquiry</h1>
      </section>
    );
  }
}

import React, { Component } from "react";
import WebApi from "../config/WebApi";

export default class WhyDs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PageData: [],
      ImgBaseUrl: "https://drupal2.24livehost.com",
    };
  }

  componentDidMount() {
    if (
      typeof window !== "undefined" &&
      window.__ROUTE_DATA__ &&
      window.__ROUTE_DATA__.apiData &&
      typeof window.__ROUTE_DATA__.apiData[1] !== "undefined"
    ) {
      this.setState({
        PageData: window.__ROUTE_DATA__.apiData[1],
      });
    }
    /*  WebApi.getRequest(
      "https://drupal2.24livehost.com/sections/homepage/why-dotsquares"
    ).then((response) => {
      // console.log("API Response", response.data);
      this.setState({
        PageData:
          response.data !== undefined && response.data !== null
            ? response.data
            : [],
      });
    }); */
  }

  render() {
    var { PageData, ImgBaseUrl } = this.state;
    return (
      <section className="why-ds-bg padding">
        <div className="wrapper">
          <div className="drupal-row">
            <div className="drupal-col-8">
              <h2>Why to Choose Dotsquares’ Drupal Developers?</h2>
              <p>
                As a leading Drupal development agency, our dedicated team of
                expert Drupal developers brings extensive technical expertise,
                such as custom module and theme development, API integrations,
                enterprise security implementation, multilingual and multi-site
                configurations, and seamless Drupal migrations and upgrades in
                building and customizing Drupal applications to deliver optimal
                results according to your business needs.
              </p>
              <p>
                {" "}
                From Drupal 7 to Drupal 9, the platform offers a vast range of
                features and Drupal modules, guaranteeing unmatched flexibility
                for customization. Whether you need a scalable enterprise
                solution or a feature-rich e-commerce platform, we leverage the
                latest Drupal capabilities to enhance functionality and
                performance.{" "}
              </p>
              <p>
                {" "}
                Partner with Dotsquares to unlock the full potential of Drupal for your business and to get the best Drupal experts.{" "}
              </p>
            </div>
            <div className="drupal-col-4">
              <div className="why-btn"></div>
            </div>
          </div>
          <div className="ds-symbol">
            {PageData !== [] &&
              PageData.map((item) => {
                return (
                  <div className="block margin-top">
                    <img
                      loading="lazy"
                      src={ImgBaseUrl + item.field_media_image}
                      alt="calenter-icon"
                      className="ls-is-cached lazyloaded"
                      height={55}
                      width={55}
                    />
                    <h3
                      dangerouslySetInnerHTML={{ __html: item.field_title }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_description,
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }
}
